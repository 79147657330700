.recipe {
  border-radius: 10px;
  box-shadow: 0px 5px 20px black;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: white;
  align-items: center;
  min-width: 40%;
}

.image {
  border-radius: 2%;
  width: 250px;
  height: 250px;
}