.App {
  min-height: 100vh;
  background-image: linear-gradient(180deg, #2af598 0%, #009efd 100%);
}

.search-form {
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-bar {
  width: 50%;
  border: none;
  padding: 10px;
}

.search-button {
  background: #11CCCC;
  border: none;
  padding: 10px 20px;
  color: white;
}

.recipes {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}